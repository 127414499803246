import { captureException } from '@sentry/nextjs';
import { useContext, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import { getFormDataAsCsv } from '../../forms';
import services from '../../lib/shared/services';
import { Vorgang } from '../../lib/shared/status';
import { asyncForEach } from '../../lib/shared/utils/asyncForEach';
import UserContext from '../UserContext';
import styles from './index.module.css';
import VorgangListenEintrag from './VorgangListenEintrag';

const GROUPS = {
	NOT_ASSIGNED: 'Nicht zugewiesene Anträge',
	CONDITION_REQUESTED: 'Kondition angefragt',
	CONDITION_SENT: 'Kondition übermittelt',
	CONTRACT_REQUESTED: 'Vertrag angefordert',
	CONTRACT_CREATED: 'Vertrag erstellt',
	CONTRACT_SENT: 'Vertrag übermittelt',
	CONTRACT_RECEIVED: 'Vertrag eingereicht',
	DOCUMENTS_REQUESTED: 'Unterlagen nachgefordert',
	BEING_CONTINUED: 'Von Bank fortgeführt',
	APPROVED: 'Kredit genehmigt',
	DECLINED: 'Kredit abgelehnt',
	PAYED_OUT: 'Kredit ausgezahlt',
	CANCELLED: 'Rücktritt'
};

const determineGroup = (item) => {
	switch (item.status) {
		case Vorgang.OFFEN:
			if (!item.bearbeiterId) {
				return GROUPS.NOT_ASSIGNED;
			}
			return GROUPS.CONDITION_REQUESTED;
		case Vorgang.KONDITIONEN_ERSTELLT:
			return GROUPS.CONDITION_REQUESTED;
		case Vorgang.VERTRAG_GEWUENSCHT:
			return GROUPS.CONTRACT_REQUESTED;
		case Vorgang.DOKUMENTE_ERHALTEN:
			return GROUPS.CONTRACT_RECEIVED;
		case Vorgang.WIRD_FORTGEFUEHRT:
			return GROUPS.BEING_CONTINUED;
		case Vorgang.PRUEFUNG_NEGATIV:
			return GROUPS.DECLINED;
		case Vorgang.KONDITIONEN_VERSCHICKT:
			return GROUPS.CONDITION_SENT;
		case Vorgang.KREDITVERTRAG_ERSTELLT:
			return GROUPS.CONTRACT_CREATED;
		case Vorgang.KREDITVERTRAG_VERSCHICKT:
			return GROUPS.CONTRACT_SENT;
		case Vorgang.UNTERLAGEN_NACHGEFORDERT:
			return GROUPS.DOCUMENTS_REQUESTED;
		case Vorgang.GENEHMIGT:
			return GROUPS.APPROVED;
		case Vorgang.ABGELEHNT:
			return GROUPS.DECLINED;
		case Vorgang.AUSGEZAHLT:
			return GROUPS.PAYED_OUT;
		case Vorgang.RUECKTRITT:
			return GROUPS.CANCELLED;
		case Vorgang.KUNDEN_RUECKTRITT:
			return GROUPS.CANCELLED;
	}
	return item.status;
};

export default function Index(props) {
	const { user } = useContext(UserContext);
	const [selectedItems, setSelectedItems] = useState([]);
	const [csvData, setCSVData] = useState([]);
	const [csvDataLoading, setCsvDataLoading] = useState(false);
	const [sortByField, setSortByField] = useState(null);
	const [sortDirection, setSortDirection] = useState(-1);
	const [isSelected, setIsSelected] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState();
	const csvLink = useRef();

	let items = props.items;

	if (sortByField !== null) {
		items = items.sort((a, b) =>
			a[sortByField] <= b[sortByField] ? sortDirection : sortDirection * -1
		);
	}

	const itemsWithGroups = items.map((item) => ({
		group: determineGroup(item),
		...item
	}));

	let _items = itemsWithGroups;

	if (props.searchText) {
		_items = itemsWithGroups.filter((e) => {
			const values = Object.values(e);

			for (let i = 0; i < values.length; i++) {
				if (!values[i]) {
					continue;
				}
				if (values[i].toString().toLowerCase().indexOf(props.searchText.toLowerCase()) !== -1) {
					return true;
				}
			}

			return false;
		});
	}

	const availableStatus = [...new Set(_.map(itemsWithGroups, (item) => item.group))];

	const groups = Object.values(GROUPS).filter((elm) => availableStatus.includes(elm));

	if (selectedGroup === undefined && groups.length > 0) {
		setSelectedGroup(groups[0]);
	}

	const onBulkSelect = async () => {
		const checkboxes = Array.from(document.getElementsByName('vorgaenge[]'));
		const selectedVorgaenge = checkboxes.filter((i) => i.checked).map((i) => i.value);

		setSelectedItems(selectedVorgaenge);
	};

	const onSelectYear = async (e) => {
		if (props.onSelectYear) {
			props.onSelectYear(e.target.value);
		}
	};

	const bulkSelectGroup = async (group, status) => {
		const checkboxes = Array.from(document.getElementsByName('vorgaenge[]')).filter((elm) => {
			return elm.dataset.group === group;
		});

		checkboxes.forEach((item) => {
			item.checked = status;
		});
		onBulkSelect();
	};

	const triggerCsvExport = async function (event, done) {
		setCsvDataLoading(true);
		const data = [];
		await asyncForEach(selectedItems, async (item) => {
			const vorgang = props.items.find((e) => e.id === item);
			try {
				const transaction = await services().transaction.get(vorgang.oppTransaction, user.bankId);

				data.push(
					getFormDataAsCsv(
						{
							darlehensbetrag: vorgang.darlehensbetrag,
							laufzeit: vorgang.laufzeit,
							...transaction.input
						},
						user
					)
				);
			} catch (err) {
				captureException(err);
			}
		});

		setCSVData(data);
		setCsvDataLoading(false);

		// click link for csv component
		// we have to use this as otherwise we would get no data on first click for csv component
		// as it starts downloading the csv blob asap and does not wait for response data from the api
		csvLink.current.link.click();
	};

	const sortBy = (field) => {
		return (e) => {
			e.preventDefault();
			setSortByField(field);
			setSortDirection(sortDirection * -1);
		};
	};

	const SortIndicator = (props) => {
		return (
			<>
				{props.field === sortByField && (
					<>
						{sortDirection === -1 && <i className='fas fa-sort-up' />}
						{sortDirection === 1 && <i className='fas fa-sort-down' />}
					</>
				)}
			</>
		);
	};

	const TableHead = (props) => {
		return (
			<th onClick={sortBy(props.field)}>
				{props.label} <SortIndicator field={props.field} />
			</th>
		);
	};

	return (
		<>
			<div>
				{groups.map((group, idx) => {
					return (
						<a
							style={{
								display: 'inline-block',
								padding: '11px 20px',
								borderRight: 'solid 1px #d6d6d6',
								background: selectedGroup !== group ? '#f0f0f0' : '#ffffff',
								fontWeight: selectedGroup === group ? 'bold' : 'normal',
								color: '#000000'
							}}
							href={`#`}
							onClick={(e) => {
								e.preventDefault();
								setSelectedGroup(group);
							}}
							key={idx}
						>
							{group}
						</a>
					);
				})}
				{props.showYearSelector && (
					<div className={styles.yearSelector}>
						Zeige Fälle aus:{' '}
						<select className='select' onChange={onSelectYear} defaultValue={props.currentYear}>
							{Array.from(Array(5).keys()).map((item) => (
								<option key={item}>{new Date().getFullYear() - item}</option>
							))}
						</select>
					</div>
				)}
			</div>
			<table className={`${styles.vorgaengeListe} ${styles.table} `}>
				<thead>
					<tr>
						<th style={{ width: '50px' }}>&nbsp;</th>
						<TableHead field='reference' label='Anfrage-ID' />
						<TableHead field='internalId' label='Interne ID' />
						<TableHead field='nachname' label='Name' />
						<TableHead field='darlehensbetrag' label='Kredit (EUR/LZ)' />
						<TableHead field='createdAt' label='Eingangszeitpunkt' />
						{!props.isPending && <TableHead field='deadline' label='Antwortfrist' />}
						{props.isPending && <TableHead field='updatedAt' label='Letzte Aktivität' />}
						<TableHead field='status' label='Status' />
						<th>Bearbeitet Von</th>
					</tr>
				</thead>
				<tbody>
					{_items
						.filter((item) => item.group === selectedGroup)
						.map((elm, elmIdx) => {
							return (
								<VorgangListenEintrag
									onBulkSelect={(itemSelected) => {
										setIsSelected(itemSelected);
										onBulkSelect();
									}}
									isPending={props.isPending}
									group={elm.status}
									isMassSelected={isSelected}
									hideAssignButton={elm.bearbeiterId === user.id && elm.bearbeiterId}
									onAssign={props.onAssign}
									onDecline={props.onDecline}
									vorgang={elm}
									alwaysRender={elmIdx < 40}
									key={`item-${elmIdx}`}
								/>
							);
						})}
				</tbody>
			</table>

			<button
				disabled={selectedItems.length === 0}
				className={`${styles.csvBtn} ${styles.btn} ${styles.weiterButton}`}
				onClick={triggerCsvExport}
			>
				({selectedItems.length}) Als CSV herunterladen
				{csvDataLoading && <i className='fa fa-spinner fa-spin' style={{ marginLeft: '0.5em' }} />}
			</button>
			<CSVLink ref={csvLink} data={csvData} filename={`${uuidv4()}.csv`} />
		</>
	);
}
