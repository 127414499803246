import React, { useState, useContext } from 'react';
import services from '../../lib/shared/services';
import VorgaengeListe from '../VorgaengeListe';
import NavBar from '../layout/NavBar';
import Sidebar from '../layout/Sidebar';
import styles from '../VorgaengeListe/index.module.css';
import UserContext from '../UserContext';
import { DemoButton } from '../buttons/demoButton/DemoButton';

const VorgaengeSeite = (props) => {
	const { user } = useContext(UserContext);
	const [items] = useState(props.items);
	const [searchText, setSearchText] = useState('');
	const [filterOwn, setFilterOwn] = useState(false);

	const reload = async () => {
		window.location.reload();
	};

	const onAssign = async (anfrage) => {
		await services().vorgang.assignToCurrentUser(anfrage.id);
		location.href = `/vorgang/${anfrage.id}`;
	};

	const onDecline = async (anfrage) => {
		if (confirm('Wollen Sie diesen Vorgang wirklich ablehnen?')) {
			await services().vorgang.assignToCurrentUser(anfrage.id);
			await services().vorgang.deny(anfrage.id, 'Sonstiges');
			await reload();
		}
	};

	const triggerKonditionAnfrage = async (e) => {
		e.preventDefault();
		await fetch('/api/demo/konditionAnfrage', { method: 'POST' });
		await reload();
	};

	const triggerVertragAnfrageNeuerVorgang = async (e) => {
		e.preventDefault();
		await fetch('/api/demo/vertragAnfrageNeuerVorgang', { method: 'POST' });
		await reload();
	};

	const triggerVertragAnfrageNeuerVorgangBank = async (e) => {
		e.preventDefault();
		await fetch('/api/demo/vertragAnfrageNeuerVorgangBank', { method: 'POST' });
		await reload();
	};

	const onSearchChange = (newSearchText) => {
		setSearchText(newSearchText);
	};

	const onFilterOwnChange = (newValue) => {
		setFilterOwn(newValue);
	};

	return (
		<>
			<NavBar onSearchChange={onSearchChange} onFilterOwnChange={onFilterOwnChange} />
			<Sidebar countsByStatus={props.countsByStatus} />
			<div className={styles.main}>
				<VorgaengeListe
					isPending={props.isPending}
					onAssign={onAssign}
					onDecline={onDecline}
					showYearSelector={props.showYearSelector}
					currentYear={props.currentYear}
					onSelectYear={props.onSelectYear}
					items={
						!filterOwn
							? items
							: items.filter((e) => {
									return e.bearbeiterId === user.id;
							  })
					}
					status={props.status}
					searchText={searchText}
				/>

				<div>
					<DemoButton onClick={triggerKonditionAnfrage}>Demo: KonditionAnfrage auslösen</DemoButton>
					<DemoButton onClick={triggerVertragAnfrageNeuerVorgang}>
						Demo: VertragAnfrage.NeuerVorgang auslösen
					</DemoButton>
					<DemoButton onClick={triggerVertragAnfrageNeuerVorgangBank}>
						Demo: VertragAnfrage.NeuerVorgang (Bank) auslösen
					</DemoButton>
				</div>
			</div>
		</>
	);
};

export default VorgaengeSeite;
