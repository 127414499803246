import { formatDate, formatAmount, formatStatus, formatBearbeiterName } from '../utils/formatting';
import { useContext, createRef, useState, useEffect } from 'react';
import UserContext from '../UserContext';
import DeadlineCountdown from '../DeadlineCountdown';
import classNames from 'classnames';
import { Vorgang } from '../../lib/shared/status';
import styles from './index.module.css';

const useOnScreen = (ref, rootMargin = '0px') => {
	// State and setter for storing whether element is visible
	const [isIntersecting, setIntersecting] = useState(false);
	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				// Update our state when observer callback fires
				setIntersecting(entry.isIntersecting);
			},
			{
				rootMargin
			}
		);
		if (ref.current) {
			observer.observe(ref.current);
		}
		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, []); // Empty array ensures that effect is only run on mount and unmount
	return isIntersecting;
};

export default function VorgangListenEintrag(props) {
	const { user } = useContext(UserContext);
	const ref = createRef();
	const isVisible = useOnScreen(ref);

	const Lc = (myProps) => {
		return (
			<td className={classNames(styles.normalColumn, myProps.className)}>
				<a href={`/vorgang/${props.vorgang.id}`}>{myProps.children}</a>
			</td>
		);
	};
	return (
		<tr ref={ref} style={{ height: '30px' }}>
			{(props.alwaysRender || isVisible) && (
				<>
					<td className={styles.massActions}>
						<input
							type='checkbox'
							data-group={props.group}
							value={props.vorgang.id}
							name='vorgaenge[]'
							onChange={props.onBulkSelect}
						/>
					</td>
					<Lc className={styles.small}>{props.vorgang.reference}</Lc>
					<Lc className={styles.small}>{props.vorgang.internalId}</Lc>
					<Lc>
						{props.vorgang.vorname} {props.vorgang.nachname}
					</Lc>
					<Lc>
						{formatAmount(props.vorgang.darlehensbetrag)} / {props.vorgang.laufzeit}
					</Lc>
					<Lc>{formatDate(props.vorgang.createdAt)}</Lc>
					{!props.isPending && (
						<Lc>
							{[Vorgang.OFFEN, Vorgang.VERTRAG_GEWUENSCHT, Vorgang.KONDITIONEN_ERSTELLT].includes(
								props.vorgang.status
							) && (
								<DeadlineCountdown
									deadline={props.vorgang.deadline}
									warnungFrist={user.bank.warnungFrist}
								/>
							)}
						</Lc>
					)}
					{props.isPending && <Lc>{formatDate(props.vorgang.updatedAt)}</Lc>}
					<Lc>{formatStatus(props.vorgang.status)}</Lc>
					<td>
						{props.vorgang.bearbeiter && formatBearbeiterName(props.vorgang.bearbeiter)}

						{!props.vorgang.bearbeiter && (
							<a
								href='#'
								onClick={(e) => {
									e.preventDefault();
									props.onAssign(props.vorgang);
								}}
							>
								+ übernehmen
							</a>
						)}
						{!props.vorgang.bearbeiter && props.vorgang.status === Vorgang.OFFEN && (
							<>
								{' '}
								<a
									href='#'
									onClick={(e) => {
										e.preventDefault();
										props.onDecline(props.vorgang);
									}}
								>
									- ablehnen
								</a>
							</>
						)}
						{props.vorgang.bearbeiter && props.vorgang.bearbeiter.id !== user.id && (
							<a
								className={styles.icon}
								href='#'
								title='Vorgang selber übernehmen'
								onClick={(e) => {
									e.preventDefault();
									props.onAssign(props.vorgang);
								}}
							>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
									<path
										fillRule='evenodd'
										d='M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z'
										clipRule='evenodd'
									/>
								</svg>
							</a>
						)}
					</td>
				</>
			)}
			{!props.alwaysRender && !isVisible && <td colSpan={9}>&nbsp;</td>}
		</tr>
	);
}
